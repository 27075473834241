<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购管理</el-breadcrumb-item>
      <el-breadcrumb-item>合同审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="top">
        <div class="top-left">状态筛选</div>
        <el-select v-model="status">
          <el-option v-for="item in zhuangtai" :key="item.label" :label="item.label" :value="item.value" @click.native="chazhuang(item.value)"></el-option>
        </el-select>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="purchase_no" label="对公采购编号" width="180"></el-table-column>
        <el-table-column prop="purchase_user" label="采购人" width="180"></el-table-column>
        <el-table-column prop="auth_users" label="审核人"></el-table-column>
        <el-table-column prop="supplier_name" label="供应商名称"></el-table-column>
        <el-table-column prop="company_name" label="采购公司"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品"></el-table-column>
        <el-table-column prop="contract_amount" label="采购合同金额"></el-table-column>
        <el-table-column prop="status_desc" label="审批状态"></el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="xiangqing(scope.row.purchase_id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!-- 弹窗 -->
    <el-dialog title="对公采购详情" :visible.sync="dialogVisible" width="70%" :before-close="handleClose" center>
      <div class="tan">
        <div class="let">申请人</div>
        <div class="el-select"><el-input v-model="purchase_user.user_name" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">供应商</div>
        <div class="el-select"><el-input v-model="supplier.name" disabled></el-input></div>
      </div>
      <div class="tan">
        <div class="let">采购公司</div>
        <div class="el-select"><el-input v-model="company.tag" disabled></el-input></div>
      </div>
      <div class="title">采购货物信息</div>
      <el-table :data="items" stripe style="width: 100%">
        <el-table-column prop="apply_user" label="采购申请人" width="90" align="center"></el-table-column>
        <el-table-column prop="house_name" label="入库仓库" width="100" align="center"></el-table-column>
        <el-table-column prop="company_name" label="采购公司" width="120" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="配件名称" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_no" label="配件编号" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_type" label="货物分类" width="100" align="center"></el-table-column>
        <el-table-column prop="apply_num" label="申请数量" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_num" label="购买数量" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_amount" label="购买总价" width="100" align="center"></el-table-column>
        <el-table-column prop="buy_price" label="购买单价" width="100" align="center"></el-table-column>
        <el-table-column prop="apply_remark" label="采购备注" width="100" align="center"></el-table-column>
      </el-table>

      <div class="title">其他费用信息</div>
      <div class="hebox">
        <el-table :data="feiyonglist" style="width: 20%" show-summary>
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column prop="fee_name" label="费用名称"></el-table-column>
          <el-table-column prop="fee_amount" label="费用金额"></el-table-column>
          <el-table-column prop="fee_remark" label="费用备注"></el-table-column>
        </el-table>
        <div class="right-box">
          <div>
            <a href="javascript:void(0)" v-if="preview_contract == undefined || preview_contract == ''" @click="notiao" class="asas">预览合同</a>
            <a v-if="preview_contract !== undefined" href="" id="link" @click="funs" target="_search" class="asas">预览合同</a>
          </div>
          <div class="rig">
            <a href="javascript:void(0)" v-if="contract_images == undefined || contract_images == ''" @click="nozhengtiao" class="asass">正式合同</a>
            <a v-if="contract_images !== undefined" href="" id="links" @click="funzheng" target="_search" class="asass">正式合同</a>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="tong">通 过</el-button>
        <el-button type="danger" @click="ju">拒 绝</el-button>
        <el-button type="primary" @click="qu">取 消</el-button>
      </span>

      <!-- 审核通过 -->
      <el-dialog title="审核" :visible.sync="loshenss" width="40%" :before-close="tongmoren" append-to-body>
        <div class="tans">
          <div class="let">审核备注</div>
          <div class="el-select"><el-input v-model="tongremark" placeholder="请输入审核备注"></el-input></div>
        </div>

        <div class="tans">
          <div class="let">下一个审核人</div>
          <div class="el-select">
            <el-select v-model="next_user" placeholder="未选择" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="tongqu">取 消</el-button>
          <el-button type="primary" @click="addgoto()">确定</el-button>
        </span>
      </el-dialog>

      <!-- 不通过弹窗 -->
      <!--  不通过弹窗 -->
      <el-dialog title="审核" :visible.sync="noshen" width="40%" :before-close="shennotongClose" append-to-body>
        <div class="tans">
          <div class="let">审核备注</div>
          <div class="el-select"><el-input v-model="noremark" placeholder="请输入审核备注"></el-input></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="notongquxiao()">取 消</el-button>
          <el-button type="primary" @click="noaddgoto()">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Caigouhelist, Caihe } from '../../../api/gongyewu'
import { Xiangqing, Caifushu, Shenheren } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      purchase_user: {}, // 头部信息
      company: {}, // 采购公司信息
      supplier: {}, // 供应商信息
      xiangtableData: [], // 详情代采购
      feiyonglist: [], // 费用信息
      items: [], // 采购明细信息
      dialogVisible: false,
      tableData: [], // 列表数据
      status: '-1', // 列表筛选
      zhuangtai: [
        { label: '全部', value: '-1' },
        { label: '待审核', value: '0' },
        { label: '审核中', value: '1' },
        { label: '审核通过', value: '2' },
        { label: '审核不通过 ', value: '3' },
        { label: '已签订  ', value: '4' }
      ],
      preview_contract: '',
      contract_images: '',
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      renlist: [],
      loshenss: false, // 通过弹窗
      tongstatus: '1',
      tongremark: '',
      next_user: '',
      id: '',
      noshen: false, // 审核不通过弹窗
      nostatus: '2',
      noremark: ''
    }
  },
  mounted() {
    this.Caigouhelists()
    this.Getrenlist()
  },
  methods: {
    notiao() {
      this.$message({
        message: '未上传预审合同',
        type: 'warning'
      })
    },
    nozhengtiao() {
      this.$message({
        message: '未上传正式合同',
        type: 'warning'
      })
    },
    funs() {
      var a = document.getElementById('link')
      a.setAttribute('href', `${this.preview_contract}`)
    },
    funzheng() {
      var a = document.getElementById('links')
      a.setAttribute('href', `${this.contract_images}`)
    },
    // 确认通过
    addgoto() {
      this.Caiheshen()
    },

    // 通过按钮
    tong() {
      this.loshenss = true
    },

    // 通过取消按钮
    tongqu() {
      this.loshenss = false
      this.tongremark = ''
      this.next_user = ''
    },

    // 通过弹窗默认关闭
    tongmoren() {
      this.tongqu()
    },

    // 拒绝
    ju() {
      this.noshen = true
    },

    // 不通过取消
    notongquxiao() {
      this.noshen = false
      this.noremark = ''
    },

    // 不通过弹窗默认关闭
    shennotongClose() {
      this.notongquxiao()
    },

    // 确定审核不通过
    noaddgoto() {
      this.Caihenotong()
    },

    // 取消
    qu() {
      this.loshenss = false
      this.noshen = false
      this.dialogVisible = false
    },

    // 查看详情
    xiangqing(id) {
      this.id = id
      this.dialogVisible = true
      this.Xiangliat(id)
      this.Caifushusitems(id)
      this.Caifushusfees(id)
    },

    // 列表状态查询
    chazhuang(val) {
      this.Caigouhelists()
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Caigouhelists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Caigouhelists()
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },

    // 采购合同列表
    async Caigouhelists() {
      const { data } = await Caigouhelist({ status: this.status, ...this.pageData })
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 采购单详情
    // 采购单列表详情
    async Xiangliat(id) {
      const { data } = await Xiangqing({ id })
      this.purchase_user = data.data.purchase_user // 购买的用户
      this.supplier = data.data.supplier // 供应商信息
      this.company = data.data.company
      this.preview_contract = data.data.preview_contract ? data.data.preview_contract[0] : ''
      this.contract_images = data.data.contract_images ? data.data.contract_images[0] : ''

      console.log(this.contract_images)
    },

    // 采购单附属信息
    async Caifushusitems(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'items' })
      this.items = data.data.items
    },

    // 采购单附属信息
    async Caifushusfees(purchase_id) {
      const { data } = await Caifushu({ purchase_id: purchase_id, type: 'fees' })
      this.feiyonglist = data.data.fees.list
    },

    // 审核人员列表
    async Getrenlist() {
      const { data } = await Shenheren()
      this.renlist = data.data
    },

    // 采购单合同审核通过
    async Caiheshen() {
      const { data } = await Caihe({ id: this.id, status: this.tongstatus, remark: this.tongremark, next_user: this.next_user })
      this.getanpostfun(data, '审核通过', this.tongmoren())
    },

    // 采购合同不通过
    async Caihenotong() {
      const { data } = await Caihe({ id: this.id, status: this.nostatus, remark: this.noremark })
      this.getanpostfun(data, '审核不通过', this.shennotongClose())
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>

<style scoped>
.top {
  display: flex;
  align-items: center;
}
.top-left {
  margin-right: 10px;
}
.tan {
  display: flex;
  align-items: center;
}
.tans {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.let {
  width: 85px;
  margin-left: 15px;
  margin-right: 10px;
}
.title {
  margin-top: 20px;
  color: red;
}
.hebox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.asas {
  width: 150px;
  height: 130px;
  /* border: 1px solid #000; */
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #409eff;
  text-decoration: none;
}
.right-box {
  width: 30%;
  display: flex;
}

.asass {
  width: 150px;
  height: 130px;
  /* border: 1px solid #000; */
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background-color: #67C23A;
  text-decoration: none;
}
.rig{
  margin-left: 20px;
}
</style>